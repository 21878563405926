import { Routes } from '@angular/router';
import { AppRoutesList } from './core/app-routes-list';

export const routes: Routes = [
  {
    path: AppRoutesList.login,
    title: 'login',
    pathMatch: 'full',
    loadComponent: () =>
      import('./components/auth/login/login.component').then(
        (c) => c.AppLoginComponent
      ),
  },
  {
    path: AppRoutesList.registration,
    title: 'registration',
    loadComponent: () =>
      import('./components/auth/registration/registration.component').then(
        (c) => c.RegistrationComponent
      ),
  },
  {
    path: AppRoutesList.setNewPassword,
    title: 'set-password',
    loadComponent: () =>
      import(
        './components/auth/reset-password/components/set-password/set-password.component'
      ).then((c) => c.SetPasswordComponent),
  },
  {
    path: AppRoutesList.resetPassword,
    title: 'reset-password',
    loadComponent: () =>
      import(
        './components/auth/reset-password/components/reset-password/reset-password.component'
      ).then((c) => c.ResetPasswordComponent),
  },
  {
    path: AppRoutesList.verifyEmail,
    title: 'email-verification',
    loadComponent: () =>
      import('./components/auth/email-verify/email-verify.component').then(
        (c) => c.EmailVerifyComponent
      ),
  },
  {
    path: AppRoutesList.demo,
    title: 'demo',
    loadChildren: () =>
      import('../../projects/demo-lib/src/lib/demo-lib.module').then(
        (m) => m.DemoLibModule
      ),
  },
];
